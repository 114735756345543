.bn-intro {
  position: fixed;
  top: 0;
  width: 100vw;
  bottom: 0;
  right: 0;
  z-index: 10000000;
  background-color: #897468;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: width 0.6s, opacity 0.6s;
  transition-timing-function: cubic-bezier(0.5, 0, 1, 0.35);
  &.--closed {
    width: 0 !important;
    opacity: 0 !important;
    pointer-events: none;
  }
}